export function urlFormatter(value: string) {
  return value
    .trim()
    .toLowerCase()
    .replace(/[^ a-z/.-\d]/g, '')
}

export function upperCase(value: string) {
  return value.toUpperCase()
}

export function formatPrice(price: number, currency = '$') {
  switch (currency) {
    case 'rub':
      return `${price}₽`
    case 'uah':
      return `₴${price}`
    default:
      return `$${price}`
  }
}

export function formatDate(dateString: string, short = false) {
  if (short) {
    return new Date(dateString).toLocaleDateString('ru', {
      month: '2-digit',
      day: 'numeric',
      year: '2-digit',
    })
  }

  return new Date(dateString).toLocaleDateString('ru', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  })
}

export function formatDateTime(dateString: string) {
  return new Date(dateString).toLocaleString('ru', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  })
}
