<template>
  <b-modal
    id="modal-delete"
    cancel-variant="outline-secondary"
    ok-only
    ok-title="Я все понимаю, продолжить"
    :ok-disabled="!isDeleteConfirmed"
    centered
    button-width="w-100"
    title="Требуется подтверждение для удаления"
    @hidden="clearForm"
    @ok="handleOk"
  >
    <b-form>
      <b-form-group>
        <b-alert show variant="warning">
          <div class="alert-body">
            Отменить данное действие будет невозможно!
          </div>
        </b-alert>

        <b-card-text>
          Введите
          <span class="font-weight-bolder">
            {{ deletionData || deleteText }}
          </span>
          , чтобы продолжить
        </b-card-text>

        <b-form-input
          id="title"
          v-model="confirmationField"
          type="text"
          class="mt-1"
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import {
  VBModal,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BAlert,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BAlert,
    BCardText,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    deletionData: {
      type: String,
    },
  },
  data: () => ({
    confirmationField: '',
    deleteText: '',
    deletePromiceResolve: null,
  }),
  computed: {
    isDeleteConfirmed() {
      if (this.deletionData)
        return (
          this.confirmationField.toLowerCase() ===
          this.deletionData.toLowerCase()
        )
      return (
        this.confirmationField.toLowerCase() === this.deleteText.toLowerCase()
      )
    },
  },
  methods: {
    clearForm() {
      this.confirmationField = ''
    },
    handleOk() {
      this.$emit('onConfirm')
      if (this.deletePromiceResolve) {
        this.deletePromiceResolve(true)
        this.deletePromiceResolve = null
      }
    },
    show(deleteText) {
      this.deleteText = deleteText
      this.$bvModal.show('modal-delete')

      return new Promise(resolve => (this.deletePromiceResolve = resolve))
    },
  },
}
</script>

<style scoped>
</style>
